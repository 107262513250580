import React, { useState, useEffect } from "react";
import { graphql } from 'gatsby';
import SEO from "../../components/seo";
import PageHero from '../../components/_organisms/page-hero/index';
import PageWrapper from '../../components/_molecules/page-wrapper/index';
import useDebounce from '../../framework/use-debounce';

import {
    Root,
    Section,
    Group,
    SectionHeader,
    GroupHeader,
    ResourceLink,
    RecommendFlag,
    FilterBlock,
    FilterHeader,
    ResourceList,
    ResourceItem
} from '../../styles/pages/resources-styles';
import { Text } from "../../components/_molecules/input/styled";

const ResourcesPage = ({ data }) => {
    const [searchTerm, setSearchTerm] = useState('');
    const debouncedSearchTerm = useDebounce(searchTerm, 200);
    const [filteredGraph, setFilteredGraph] = useState(null);

    useEffect(() => {
        setFilteredGraph(data.api.knowledgegraph);
    }, []);

    useEffect(() => {
          filter(debouncedSearchTerm);
    }, [debouncedSearchTerm]);

    const filter = filterText => {
        const graphWithFilter = {
            sections: data.api.knowledgegraph.sections.map(s => ({
                name: s.name,
                groups: s.groups.map(g => ({
                    name: g.name,
                    resources: g.resources.filter(r => r.title.toLowerCase().indexOf(filterText.toLowerCase()) > -1)
                })).filter(g => g.resources.length > 0)
            })).filter(s => s.groups.length > 0)
        };

        setFilteredGraph(graphWithFilter);
    };

    return (
        <>
            <SEO
                title={data.resourcesPage.title}
                description={data.resourcesPage.metaDescription}
                image={(data.resourcesPage.openGraphImage && data.resourcesPage.openGraphImage[0].src.childImageSharp.fixed.src) || null}
                slug="/resources"
            />
            <PageHero
                title={data.resourcesPage.title}
                images={data.resourcesPage.heroImages}
                description={data.resourcesPage.metaDescription}
            />
            <PageWrapper>
                <Root>
                    <FilterBlock>
                        <FilterHeader>Filter</FilterHeader>
                        <Text onChange={event => setSearchTerm(event.target.value)} aria-label="filter-input" />
                    </FilterBlock>                
                    {
                        filteredGraph && filteredGraph.sections.map(s => (
                            <div>
                                <SectionHeader>{ s.name }</SectionHeader>
                                <Section>
                                    { s.groups.map(g => (
                                        <Group>
                                            <GroupHeader>{ g.name }</GroupHeader>
                                            <ResourceList>
                                            { g.resources.map(r => (
                                                <ResourceItem>
                                                    <ResourceLink href={r.url} target="_blank">
                                                        { r.title }{ r.recommended && <RecommendFlag>*</RecommendFlag>}
                                                    </ResourceLink>
                                                </ResourceItem>
                                            ))
                                            }
                                            </ResourceList>
                                        </Group>
                                    )) }
                                </Section>
                            </div>
                        ))
                    }
                </Root>
            </PageWrapper>
        </>
    )    
}

export const pageQuery = graphql`
query resourcesPageQuery {
    resourcesPage: jsonJson(id: {eq: "resources_page"}) {
      id
      title
      metaDescription
      heroImages {
        name
        src {
            childImageSharp {
                fixed(width: 700, height: 350, cropFocus: NORTH) {
                    ...GatsbyImageSharpFixed
                }  
                fluid(quality: 90, maxWidth: 1920, maxHeight: 350) {
                    ...GatsbyImageSharpFluid_withWebp_tracedSVG
                }
                fluidThumb: fluid(quality: 90, maxWidth: 1920, maxHeight: 1080, cropFocus: NORTH) {
                    ...GatsbyImageSharpFluid_withWebp
                }               
            }   
        }
      }
      openGraphImage: heroImages {
        name
        src {
          childImageSharp {
            fixed(width: 1200, height: 630) {
              src
            }
          }
        }
      }
      bodyContent: _bodyContent
    }
    api: g33kchris {
        knowledgegraph {
            sections {
                name
                groups {
                    name
                    resources {
                        url
                        title
                        saveDate
                        recommended
                        articleDate
                    }
                }
            }
        }
    }
  }
`;

export default ResourcesPage;