import styled from 'styled-components';
import { headerFontMixin, articleFont } from '../_mixins';
import { fluidFontSizes } from '../_calculations';
import { textPrimary, textSecondary, darkGrey, borderRadius, rightAccentColorLighter, rightAccentColorDarker, leftAccentColorLighter } from '../_theme-variables';

const headerColor = textPrimary;
const linkColor = textSecondary;
const filterBgColor = darkGrey;
const filterHeaderColor = leftAccentColorLighter;
const groupHeaderColor = props => props.theme.darkMode 
    ? leftAccentColorLighter
    : rightAccentColorDarker;

export const Root = styled.div`
    display: flex;
    flex-direction: column;
`;

export const Section = styled.div`
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    ${fluidFontSizes.bodySize}
    margin-bottom: 1em;
`;

export const Group = styled.div`
    flex-basis: 100%;
    ${fluidFontSizes.bodySize}
    padding-right: 2em;
    margin-bottom: 2em;

    @media screen and (min-width: 37.5em) {
        flex-basis: 50%;
    }

    @media screen and (min-width: 50em) {
        flex-basis: 33.33%;
    }
`;

export const SectionHeader = styled.h3`
    ${headerFontMixin}
    ${fluidFontSizes.header5Size}
    color: ${headerColor};
    margin-bottom: 1em;
`;

export const GroupHeader = styled.h4`
    ${headerFontMixin}
    ${fluidFontSizes.header6Size}
    color: ${groupHeaderColor};
    padding-bottom: 0.5em;
    border-bottom: 1px solid ${groupHeaderColor};
    margin-bottom: 0.5em;
`;

export const ResourceList = styled.ul`
    list-style: square;
    padding-left: 1em;
`;

export const ResourceItem = styled.li`
    margin-bottom: 0.7em; 
    color: ${headerColor};
`;

export const ResourceLink = styled.a`
    ${articleFont}
    ${fluidFontSizes.smallPrintSize}
    display: block;
    margin-bottom: 0.5em;
    color: ${headerColor};
    text-decoration: none;

    &:hover {
        color: ${linkColor};
        text-decoration: underline;
    }
`;

export const RecommendFlag = styled.span`
    color: ${headerColor};
`;

export const FilterBlock = styled.div`
    background-color: ${filterBgColor};
    color: ${linkColor};
    ${fluidFontSizes.bodySize}
    padding: 1em;
    margin-bottom: 2em;
    border-radius: ${borderRadius};
`;

export const FilterHeader = styled(GroupHeader)`
    color: ${filterHeaderColor};
    border-bottom: 1px solid ${filterHeaderColor};
`